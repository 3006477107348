import { BehaviorSubject } from 'rxjs';
import Store from '../../types/Store';

interface SelectedStoreSubject {
  selectedStore: Store | undefined;
  isLoading: boolean;
  error: Error | undefined;
}

const selectedStore = new BehaviorSubject<SelectedStoreSubject>({
  selectedStore: undefined,
  isLoading: false,
  error: undefined,
});

export default selectedStore;
