import { BehaviorSubject } from 'rxjs';
import UserSettings from '../../types/UserSettings';

interface UserSettingsSubject {
  userSettings: UserSettings | undefined;
  isLoading: boolean;
  error: Error | undefined;
}

const userSettings = new BehaviorSubject<UserSettingsSubject>({
  userSettings: undefined,
  isLoading: false,
  error: undefined,
});

export default userSettings;
