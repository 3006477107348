import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, {
  BugsnagErrorBoundary,
} from '@bugsnag/plugin-react';
import React from 'react';
import AppConstants from '../../AppConstants';
import env from '../../env';
import sendErrorFilter from './sendErrorFilter';
import sendBreadcrumbFilter from './sendBreadcrumbFilter';

let AppBugsnagErrorBoundary: BugsnagErrorBoundary | undefined;

const initializeBugsnag = (appVersion: string): void => {
  if (!AppConstants.BUGSNAG_ENABLED) {
    return;
  }

  Bugsnag.start({
    autoTrackSessions: false,
    apiKey: env.BUGSNAG_API_KEY,
    appVersion,
    maxBreadcrumbs: 100,
    maxEvents: 100,
    trackInlineScripts: false,
    releaseStage: env.APP_ENVIRONMENT,
    plugins: [new BugsnagPluginReact(React)],
    onError: event => {
      // mark as handled until we determine if it has hit the error boundary
      // only errors that crash the app should be marked as unhandled
      event.unhandled = false;

      // group events by message for unhandled, otherwise by context
      event.groupingHash =
        event?.unhandled && event?.errors?.[0]?.errorMessage
          ? event.errors[0].errorMessage
          : event.context;

      return sendErrorFilter(event);
    },
    onBreadcrumb: breadcrumb => sendBreadcrumbFilter(breadcrumb),
  });
  Bugsnag.startSession();
  console.log('Bugsnag initialized');
  AppBugsnagErrorBoundary =
    Bugsnag?.getPlugin('react')?.createErrorBoundary(React);
};

export const getBugsnagErrorBoundary = () => AppBugsnagErrorBoundary;

export default initializeBugsnag;
