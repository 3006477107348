interface AccessToken {
  accessToken?: string;
}

interface OktaTokenStorage {
  accessToken?: AccessToken;
}

const getAccessToken = (): string => {
  const oktaTokenStorage: OktaTokenStorage = JSON.parse(
    localStorage.getItem('okta-token-storage') ?? '{}',
  );
  return oktaTokenStorage?.accessToken?.accessToken ?? '';
};

export default getAccessToken;
