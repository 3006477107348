import { BehaviorSubject } from 'rxjs';

export enum AppNames {
  CARES = 'Cares',
  DIGITAL_ORDERING = 'Digital Ordering',
  DOP = 'DOP',
  MENU = 'Menu',
  CFA_DELIVERY = 'CFA Delivery',
  KPS = 'KPS',
  POS = 'POS',
  STORE_LISTING = 'Store Listing',
  VENUES = 'Venues',
  VIEWPOINT = 'ViewPoint',
}

interface PublishCartGroupsSubject {
  appName: AppNames | null;
  dataPoints: string[];
}

const publishCartDataPoints = new BehaviorSubject<PublishCartGroupsSubject>({
  appName: null,
  dataPoints: [],
});

export default publishCartDataPoints;
