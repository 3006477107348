import Bugsnag from '@bugsnag/js';
import AppError from '../../types/AppError';
import AppConstants from '../../AppConstants';
import sendErrorFilter from './sendErrorFilter';

interface BugsnagDebug {
  context: string;
  response: AppError;
  appName?: string;
  appVersion?: string;
}

const logError = (errorClass: string, debug: BugsnagDebug): void => {
  if (!AppConstants.BUGSNAG_ENABLED) {
    return;
  }

  const error =
    debug.response instanceof Error ? debug.response : new Error(errorClass);

  return Bugsnag.notify(error, event => {
    if (!sendErrorFilter(event)) {
      return false;
    }
    // If debug.context provided, Displays as Title (1st line) in Bugsnag, else errorClass displayed
    event.context = `${debug.context} Failure`;
    event.addMetadata('errorInfo', { ...debug });
    event.groupingHash = event.context;
    return true;
  });
};

export default logError;
