import { BehaviorSubject } from 'rxjs';

interface AppLoadingStatusesSubject {
  caresLoaded: boolean;
  dopLoaded: boolean;
  kpsLoaded: boolean;
  menuLoaded: boolean;
  deliveryLoaded: boolean;
  posLoaded: boolean;
  storeListingLoaded: boolean;
  venuesLoaded: boolean;
  viewPointLoaded: boolean;
}

const appLoadingStatuses = new BehaviorSubject<AppLoadingStatusesSubject>({
  caresLoaded: false,
  dopLoaded: false,
  kpsLoaded: false,
  menuLoaded: false,
  deliveryLoaded: false,
  posLoaded: false,
  storeListingLoaded: false,
  venuesLoaded: false,
  viewPointLoaded: false,
});

export default appLoadingStatuses;
