import { z } from 'zod';

export const changeEventSchema = z.object({
  area: z.string(),
  group: z.string(),
  label: z.string(),
  return_route: z.string().optional(),
  sort_order: z.string().optional(),
  text: z.string().optional(),
  data_point: z.string(),
  timestamp: z.number(),
});

type ChangeEvent = z.infer<typeof changeEventSchema>;

export default ChangeEvent;
