import { BehaviorSubject } from 'rxjs';
import ChangeEvent from '../../types/ChangeEvent';

interface CartSubject {
  cart: ChangeEvent[];
  isLoading: boolean;
  error: Error | undefined;
}

const cart = new BehaviorSubject<CartSubject>({
  cart: [],
  isLoading: false,
  error: undefined,
});

export default cart;
