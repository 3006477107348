import { Breadcrumb } from '@bugsnag/js';

const sendBreadcrumbFilter = (breadcrumb: Breadcrumb): boolean => {
  // Remove unhelpful properties, keeps our breadcrumbs clean
  if (breadcrumb.type === 'user') {
    // Discard clicks with general targetText
    if (breadcrumb.metadata.targetText === '(...)') {
      return false;
    }
    delete breadcrumb.metadata.targetSelector;
  }
  if (breadcrumb.type === 'navigation') {
    delete breadcrumb.metadata.title;
    delete breadcrumb.metadata.state;
    delete breadcrumb.metadata.prevState;
  }

  return true;
};

export default sendBreadcrumbFilter;
