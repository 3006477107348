import { BehaviorSubject } from 'rxjs';
import FeatureFlags from './FeatureFlags';

interface FeatureFlagsSubject {
  featureFlags: FeatureFlags[];
  isLoading: boolean;
  error: Error | undefined;
}

const featureFlags = new BehaviorSubject<FeatureFlagsSubject>({
  featureFlags: [],
  isLoading: false,
  error: undefined,
});

export default featureFlags;
