import { z } from 'zod';

const envSchema = z.object({
  APP_ENVIRONMENT: z.enum(['dev', 'test', 'stage', 'prod']),
  BUGSNAG_API_KEY: z.string().min(1),
});

const env = envSchema.parse(process.env);

export default env;
