import { BehaviorSubject } from 'rxjs';
import KpsChange from '../../types/KpsChange';

interface PublishKpsCartSubject {
  cart: KpsChange[];
  backupName: string;
  date?: string;
}

const publishKpsCart = new BehaviorSubject<PublishKpsCartSubject>({
  cart: [],
  backupName: '',
  date: '',
});

export default publishKpsCart;
