import coreService from '../../services/coreService';
import cart from './index';

const getCart = async (storeNumber: string) => {
  cart.next({
    cart: cart.value.cart,
    isLoading: true,
    error: undefined,
  });
  try {
    const response = await coreService.getChangeEvents(storeNumber);
    const updatedResponse = response.map(res => {
      if (res.label === 'Job Opportunities Url') {
        return {
          ...res,
          area: 'Store Listing',
        };
      } else {
        return res;
      }
    });
    cart.next({
      cart: updatedResponse,
      isLoading: false,
      error: undefined,
    });
    return updatedResponse;
  } catch (e) {
    cart.next({
      cart: cart.value.cart,
      isLoading: false,
      error: e,
    });
  }
};

export default getCart;
