import spcService from '../../services/spcService';
import cart from './index';

const getKpsCart = async (storeNumber: string) => {
  cart.next({
    cart: cart.value.cart,
    isLoading: true,
    error: undefined,
  });
  try {
    const response = await spcService.getCart(storeNumber);
    cart.next({
      cart: response,
      isLoading: false,
      error: undefined,
    });
    return response;
  } catch (e) {
    cart.next({
      cart: cart.value.cart,
      isLoading: false,
      error: e,
    });
  }
};

export default getKpsCart;
