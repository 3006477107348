import { BehaviorSubject } from 'rxjs';

interface PlatformToastBehaviorSubject {
  toastInfo?: {
    title?: string;
    variant: 'success' | 'error' | 'warning' | 'info';
    content: string;
  };
}

const platformToast = new BehaviorSubject<PlatformToastBehaviorSubject>({
  toastInfo: undefined,
});

export default platformToast;
