enum NODE_ENV {
  DEVELOPMENT = 'development',
  TEST = 'test',
  PRODUCTION = 'production',
}

enum APP_ENVIRONMENT {
  DEV = 'dev',
  TEST = 'test',
  STAGE = 'stage',
  PROD = 'prod',
}

const AppConstants = {
  NODE_ENV,
  APP_ENVIRONMENT,
  BUGSNAG_ENABLED: process.env.NODE_ENV !== NODE_ENV.TEST || !!window.Cypress,
};

export default AppConstants;
