import { z } from 'zod';
import AppConstants from '../AppConstants';
import UserSettings, { userSettingsSchema } from '../types/UserSettings';
import ChangeEvent, { changeEventSchema } from '../types/ChangeEvent';
import FeatureFlags from '../utils/featureFlags/FeatureFlags';
import Store, { storeSchema } from '../types/Store';
import env from '../env';
import createApiClient from './createApiClient';

const baseUrlMap: { [key: string]: string } = {
  [AppConstants.APP_ENVIRONMENT.DEV]: 'https://core.dev-ctrl.cfahome.com',
  [AppConstants.APP_ENVIRONMENT.TEST]: 'https://core.test-ctrl.cfahome.com',
  [AppConstants.APP_ENVIRONMENT.STAGE]:
    'https://core.stage-ctrl.cfadevelop.com',
  [AppConstants.APP_ENVIRONMENT.PROD]: 'https://core.ctrl.cfahome.com',
};

const baseUrl = baseUrlMap[env.APP_ENVIRONMENT];

const apiClient = createApiClient(baseUrl);

const coreService = {
  getChangeEvents: (storeNumber: string) =>
    apiClient
      .get(`${storeNumber}/changeevents`, {
        validationSchema: z.array(changeEventSchema),
      })
      .json<ChangeEvent[]>(),
  getUserSettings: () =>
    apiClient
      .get('user/settings', {
        validationSchema: userSettingsSchema,
      })
      .json<UserSettings>(),
  getFeatureFlags: (storeNumber: string) =>
    apiClient
      .get(`store/features/${storeNumber}`, {
        validationSchema: z.array(z.nativeEnum(FeatureFlags)),
      })
      .json<FeatureFlags[]>(),
  getStore: (storeNumber: string) =>
    apiClient
      .get(`stores/store/${storeNumber}`, {
        validationSchema: storeSchema,
      })
      .json<Store>(),
} as const;

export default coreService;
