import Bugsnag from '@bugsnag/js';
import AppConstants from '../../AppConstants';

const leaveBreadcrumb = (name: string, action: string): void => {
  if (!AppConstants.BUGSNAG_ENABLED) {
    return;
  }

  return Bugsnag.leaveBreadcrumb(name, { action });
};

export default leaveBreadcrumb;
