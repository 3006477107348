import { BehaviorSubject } from 'rxjs';
import { AppNames } from './cart/publishCartDataPoints';

interface CartDataPointsSubject {
  [AppNames.CARES]: null | { [key: string]: string };
  [AppNames.DIGITAL_ORDERING]: null | { [key: string]: string };
  [AppNames.DOP]: null | { [key: string]: string };
  [AppNames.CFA_DELIVERY]: null | { [key: string]: string };
  [AppNames.MENU]: null | { [key: string]: string };
  [AppNames.STORE_LISTING]: null | { [key: string]: string };
  [AppNames.POS]: null | { [key: string]: string };
  [AppNames.VENUES]: null | { [key: string]: string };
  [AppNames.VIEWPOINT]: null | { [key: string]: string };
}

const cartDataPoints = new BehaviorSubject<CartDataPointsSubject>({
  [AppNames.CARES]: null,
  [AppNames.DIGITAL_ORDERING]: null,
  [AppNames.DOP]: null,
  [AppNames.CFA_DELIVERY]: null,
  [AppNames.MENU]: null,
  [AppNames.STORE_LISTING]: null,
  [AppNames.POS]: null,
  [AppNames.VENUES]: null,
  [AppNames.VIEWPOINT]: null,
});

export default cartDataPoints;
