import { BehaviorSubject } from 'rxjs';
import { AppNames } from './publishCartDataPoints';

interface DeleteCartDataPointsSubject {
  appName: AppNames | null;
  dataPoints: string[];
}

const deleteCartDataPoints = new BehaviorSubject<DeleteCartDataPointsSubject>({
  appName: null,
  dataPoints: [],
});

export default deleteCartDataPoints;
