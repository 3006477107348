import { BehaviorSubject } from 'rxjs';
import KpsChange from '../../types/KpsChange';

interface CartSubject {
  cart: KpsChange[];
  isLoading: boolean;
  error: Error | undefined;
}

const kpsCart = new BehaviorSubject<CartSubject>({
  cart: [],
  isLoading: false,
  error: undefined,
});

export default kpsCart;
