import coreService from '../../services/coreService';
import selectedStore from './index';

const getSelectedStore = async (storeNumber: string): Promise<void> => {
  selectedStore.next({
    selectedStore: undefined,
    isLoading: true,
    error: undefined,
  });
  try {
    const response = await coreService.getStore(storeNumber);
    selectedStore.next({
      selectedStore: response,
      isLoading: false,
      error: undefined,
    });
  } catch (e) {
    selectedStore.next({
      selectedStore: undefined,
      isLoading: false,
      error: e as Error,
    });
  }
};

export default getSelectedStore;
