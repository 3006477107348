import { z } from 'zod';

export const storeSchema = z.object({
  storeNumber: z.string(),
  storeName: z.string(),
  address: z.string(),
  city: z.string(),
  state: z.string(),
  zipCode: z.string(),
  country: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  gmtOffset: z.string(),
  timeZoneName: z.string(),
  timeZoneId: z.string(),
  status: z.string(),
});

type Store = z.infer<typeof storeSchema>;

export default Store;
