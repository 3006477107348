import { z } from 'zod';

const userDeviceSchema = z.object({
  browser_name: z.string().optional(),
  browser_version: z.string().optional(),
  operatingSystem_name: z.string().optional(),
  operatingSystem_version: z.string().optional(),
  device_model: z.string().optional(),
  device_type: z.string().optional(),
  device_vendor: z.string().optional(),
  screen_width: z.string().optional(),
  screen_height: z.string().optional(),
  last_used_timestamp: z.string().optional(),
});

export const userSettingsSchema = z.object({
  acceptedCaresTerms: z.boolean().optional(),
  acceptedCaresTerms_URL: z.string().optional(),
  doNotShowPublishConfirmation: z.boolean().optional(),
  preferredTheme: z.string().optional(),
  dismissedNewChangesPrompt: z.boolean().optional(),
  lastSelectedStore: z.string().optional(),
  lastSelectedStoreNumber: z.string().optional(),
  recentSearches: z.string().optional(),
  favoriteStores: z.string().array().optional(),
  dismissedWhatsNewJuly92020: z.boolean().optional(),
  dismissedLettuceToggleMessage: z.boolean().optional(),
  devices: z.array(userDeviceSchema).optional(),
});

type UserSettings = z.infer<typeof userSettingsSchema>;

export default UserSettings;
