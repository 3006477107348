import { BehaviorSubject } from 'rxjs';
import RouteInfo from '../types/RouteInfo';

interface GlobalRoutesSubject {
  routes: RouteInfo[];
}

const globalRoutes = new BehaviorSubject<GlobalRoutesSubject>({
  routes: [],
});

export default globalRoutes;
