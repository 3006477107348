import { Event } from '@bugsnag/js';

const sendErrorFilter = (event: Event): boolean => {
  // Exclude error messages with these specific labels
  const excludeErrorMessagesWithTheseLabels: string[] = [];
  // Exclude errors loading tracking scripts
  const trackingDomains: string[] = [];
  // Sometimes tracking seems to get blamed for real issues, so only exclude these ones
  const trackingDomainExcludeErrors: string[] = [];
  const eventErrorsExist = !!event.errors.length;
  const hasErrorMessage = event.errors.some(error => error.errorMessage.length);
  const isExcludedErrorMessage = event.errors.some(error =>
    excludeErrorMessagesWithTheseLabels.find(msg =>
      error.errorMessage?.includes(msg),
    ),
  );
  const isExcludedDomainAndError = event.errors.some(
    error =>
      trackingDomains.find(
        domain =>
          !error?.stacktrace?.some(trace => trace?.file?.includes(domain)),
      ) &&
      trackingDomainExcludeErrors.find(
        msg => !error.errorMessage?.includes(msg),
      ),
  );
  return (
    eventErrorsExist ||
    hasErrorMessage ||
    isExcludedErrorMessage ||
    isExcludedDomainAndError
  );
};

export default sendErrorFilter;
