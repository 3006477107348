import coreService from '../../services/coreService';
import userSettings from './index';

const getUserSettings = async (): Promise<void> => {
  userSettings.next({
    userSettings: undefined,
    isLoading: true,
    error: undefined,
  });
  try {
    const response = await coreService.getUserSettings();
    userSettings.next({
      userSettings: response,
      isLoading: false,
      error: undefined,
    });
  } catch (err) {
    userSettings.next({
      userSettings: undefined,
      isLoading: false,
      error: err as Error,
    });
  }
};

export default getUserSettings;
