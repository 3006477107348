import coreService from '../../services/coreService';
import featureFlags from './index';

const getFeatureFlags = async (storeNumber: string) => {
  featureFlags.next({
    featureFlags: featureFlags.value.featureFlags,
    isLoading: true,
    error: undefined,
  });
  try {
    const response = await coreService.getFeatureFlags(storeNumber);
    featureFlags.next({
      featureFlags: response,
      isLoading: false,
      error: undefined,
    });
    return response;
  } catch (e) {
    featureFlags.next({
      featureFlags: featureFlags.value.featureFlags,
      isLoading: false,
      error: e,
    });
  }
};

export default getFeatureFlags;
