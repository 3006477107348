import { z } from 'zod';

export const kpsChangeSchema = z.object({
  topLevelGroupName: z.string(),
  topLevelGroupNameFriendlyPrint: z.string(),
  settingGroupName: z.string(),
  settingGroupNameFriendlyPrint: z.string(),
  settingGroupSuffix: z.string(),
  settingName: z.string(),
  currentValue: z.string(),
  lastValue: z.string(),
  originalValue: z.string(),
  valuePrefix: z.string(),
  sortOrder: z.number(),
  message: z.string(),
  changeEventFriendlyPrint: z.string(),
  returnRoute: z.string(),
  requiresReboot: z.boolean(),
  posRequiresReboot: z.boolean(),
  timeStamp: z.number(),
  changedBy: z.string(),
  active: z.boolean(),
  canCancel: z.boolean(),
});

type KpsChange = z.infer<typeof kpsChangeSchema>;

export default KpsChange;
